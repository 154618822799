
  import { defineComponent, onMounted, onUnmounted } from "vue";
  import { useStore } from "vuex";

  import { reactive } from "vue";
  import { ref } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { getUsersRequest } from "@/latipay/apis/services/UsersService";
  import { reinitializeComponents } from "@/core/plugins/keenthemes";
  import { useI18n } from "vue-i18n";
  import { getIllustrationsPath } from "@/core/helpers/assets";




  export default defineComponent({
    name: "users",
    components: {},
    setup() {
      const store = useStore();
      const route = useRoute();
      const router = useRouter();
      const { locale } = useI18n();

      const name = ref<string | null>(null);
      const email = ref<string | null>(null);
      const status = ref<string | null>(null);
      const sort = ref<string | null>(null);
      const pageNumber = ref(1);
      const pageSize = ref(20);

      const loading = ref(false);


      const formInline = reactive({
        name: "",
        status: "",
        email: ""
      });

      const {
        usersRef,
        totalRef,
        pageNumberRef,
        pageSizeRef,
        getUsers
      } = getUsersRequest();


      const getData = async () => {
        loading.value = true;

        // await updateQueries();
        await getUsers(
          formInline.name,
          formInline.email,
          formInline.status,

          sort.value,
          pageNumber.value,
          pageSize.value
        );
        reinitializeComponents();

        loading.value = false;

        console.log(usersRef);
      };

      console.log(usersRef);

      onMounted(async () => {
        // setCurrentPageTitle("Users");
        await getData();
      });

      const updatePageSize = async (val: number) => {
        console.log(`${val} items per page`);
        pageSize.value = val;
        await getData();
      };
      const updatePageNumber = async (val: number) => {
        console.log(`current page: ${val}`);
        pageNumber.value = val;
        await getData();
      };


      return {
        getIllustrationsPath,

        formInline,
        getData,
        loading,
        updatePageSize,
        updatePageNumber,
        usersRef,
        totalRef,
        pageNumberRef,
        pageSizeRef,
        name,
        email,
        status,




      };

    },
    methods: {
      async resetFormFields() {

        (this.$refs["searchForm"] as any).resetFields();

        await this.getData();

      },
      async onSubmitSearchForm() {
        await this.getData();
      }

    }
  });
